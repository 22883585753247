import React from "react";

const Solutions = () => (
  <div className="bg-gray-100 text-center space-y-10 py-32">
    <div className="text-xl px-20 md:px-40">
      <p>Waivpay is the largest supplier of gifting solutions to independent shopping centres in Australia.</p>
      <p>Waivpay’s suite of products  allow shopping centres the ability to service their customers as and when required require.</p>
      <p>
        Supporting Mastercard digital and EFTPOS physical cards.
        All funds are held in trust to protect your customer and supported by an Australian based customer service team.
      </p>
    </div>
  </div>
);

export default Solutions;
